import Footer from "../../Components/Footer"
import Header from "../../Components/Header"

import HomeSlider from "./Elements/home_slider"
import React, { useEffect, useRef, useState } from "react";

import Roomedits from "./Elements/home_room_edits"
import HomeBlog from "./Elements/home_blog"
import HomeTopFooter from "./Elements/home_top_footer"
import { useNavigate } from "react-router-dom"
import HomeAppointment from "./Elements/home_appointment"
import HomeBelowBanner from "./Elements/home_below_banner"
import { BrowserView , MobileView } from "react-device-detect"
import { ApiService } from "../../Components/Services/apiservices"
import Marquee from "react-fast-marquee";
import TagWiseProducts from "../Element/tag_wise_products"
import CategoryWiseProducts from "../Element/category_wise_products"
import CategoriesList from "../Element/categories_list"



const Home = () => {
    const didMountRef = useRef(true);
    const [browserSectionData, setBrowserSectionData] = useState([])
    const [mobileSectionData, setMobileSectionData] = useState([])
    const [mediaImageUrl, setmediaImageUrl] = useState("")
    const videoRefs = useRef([]);
    const [playingIndex, setPlayingIndex] = useState(null);
    useEffect(() => {
      if (didMountRef.current) {
        getDashboardData()
  
      }
      didMountRef.current = false
    })
    const getDashboardData = () => {
      ApiService.fetchData("dashboard").then((res) => {
        if (res.status == "success") {
          setBrowserSectionData(res.resAppearanceHomeBrowserData)
          setMobileSectionData(res.resAppearanceHomeMobileData)
          setmediaImageUrl(res.mediaImageUrl)
        } 
      }).catch(() => {
      });
    };
    const convertStyleStringToObject = (styleString) => {
      if (!styleString) return {}; // Return an empty object if styleString is null or undefined
      return styleString.split(';').reduce((styleObject, styleProperty) => {
        if (styleProperty) {
          const [property, value] = styleProperty.split(':');
          if (property && value) {
            const camelCasedProperty = property.trim().replace(/-([a-z])/g, (match, letter) => letter.toUpperCase());
            styleObject[camelCasedProperty] = value.trim();
          }
        }
        return styleObject;
      }, {});
    };
  
    const handleVideoClick = (index) => {
      const videoElement = videoRefs.current[index];
      if (videoElement) {
        if (videoElement.paused) {
          if (playingIndex !== null && playingIndex !== index) {
            videoRefs.current[playingIndex].pause();
          }
          videoElement.play();
          setPlayingIndex(index);
        } else {
          videoElement.pause();
          setPlayingIndex(null);
        }
      }
    };
    return (<>
        <Header></Header>
        <BrowserView>
        <HomeSlider></HomeSlider>
        <HomeBelowBanner></HomeBelowBanner>
        {browserSectionData.length > 0 && (
        browserSectionData.map((valueSection, indexSection) => {
          const inlineStyle = convertStyleStringToObject(valueSection.home_inline_style);
          if (valueSection.home_layout_image) {
            inlineStyle.background = `url(${valueSection.home_layout_image}) ${valueSection.home_layout_background_repeat}`;
          }
          return (
            <section className={`${valueSection.home_top_spaced} ${valueSection.home_bottom_spaced} ${valueSection.home_extra_class}`} key={valueSection.home_id} style={inlineStyle}>
              <div className={`${valueSection.home_layout} ${valueSection.home_grid === 'g-0' ? 'px-0' : ''}`}>
                <div className={`row ${valueSection.home_grid} ${valueSection.home_vertical_align} ${valueSection.home_horizontal_align}`}>
                  {valueSection.has_many_home_inner.length > 0 &&
                    valueSection.has_many_home_inner.map((valueRowInner, indexRowInner) => (
                      <><div className={"col-lg-" + valueRowInner.home_inn_structure_type} key={indexRowInner}>
                        {valueRowInner.has_many_home_details.length > 0 &&
                          valueRowInner.has_many_home_details.map((valueRow, indexRow) => {
                            let dataObj;
                            if (valueRow.home_type === 1) { //Products
                              if (valueRow.home_layout_for_type === 1) {
                                dataObj = {
                                  'id': valueRow.home_cat_id,
                                  'home_product_name': valueRow.home_product_name,
                                  'home_column': valueRow.home_column,
                                  'home_display_type': valueRow.home_display_type,
                                  'home_no_items': valueRow.home_no_items,
                                  'home_slider_display_title': valueRow.home_slider_display_title,
                                  'home_slider_display_type': valueRow.home_slider_display_type,
                                  'home_product_style': valueRow.home_product_style,
                                  'home_col_grid': 'cols-xl-',
                                };
                                return (
                                   
                                  <CategoryWiseProducts key={indexRow} dataObj={dataObj} />
                                );
                              } else {
                                dataObj = {
                                  'id': valueRow.home_tag_id,
                                  'home_product_name': valueRow.home_product_name,
                                  'home_column': valueRow.home_column,
                                  'home_display_type': valueRow.home_display_type,
                                  'home_slider_display_title': valueRow.home_slider_display_title,
                                  'home_no_items': valueRow.home_no_items,
                                  'home_slider_display_type': valueRow.home_slider_display_type,
                                  'home_product_style': valueRow.home_product_style,
                                  'home_col_grid': 'cols-xl-',
                                };
                                return (
                                  
                                  <TagWiseProducts key={indexRow} dataObj={dataObj} />
                                );
                              }
                            } else if (valueRow.home_type === 2) { //Categories
                              dataObj = {
                                'home_cat_title': valueRow.home_cat_title,
                                'home_column': valueRow.home_column,
                                'home_display_type': valueRow.home_display_type,
                                'home_slider_display_title': valueRow.home_slider_display_title,
                                'home_cat_multi_id': valueRow.home_cat_multi_id,
                                'home_cat_display_title': valueRow.home_cat_display_title,
                                'home_category_style': valueRow.home_category_style,
                              };
                              return (
                               
                                <CategoriesList
                                  key={indexRow}
                                  dataObj={dataObj}
                                />
                              );
                            } else if (valueRow.home_type === 3) { //Tags
                              return (
                                <p key={indexRow}>{valueRow.home_type}</p>
                              );
                            } else if (valueRow.home_type === 4) { //Text
                              return (
                                <div key={indexRow}>
                                  {valueRow.home_text_title && (<p>{valueRow.home_text_title}</p>)}
                                  <div dangerouslySetInnerHTML={{ __html: valueRow.home_text_description }} ></div>
                                </div>
                              );
                            } else if (valueRow.home_type === 5) { //Images
                              const imageInlineStyle = convertStyleStringToObject(valueRow.home_custom_size);
                              return (
                                <div className="overlay-zoom">
                                <a href={valueRow.home_image_link} target={valueRow.home_target}>
                                  <img src={valueRow.home_image} key={indexRow} alt={valueRow.home_caption_text} className={`${valueRow.home_size}`} style={imageInlineStyle} />
                                </a>
                                </div>
                              );
                            } else if (valueRow.home_type === 6) { //Image Gallery
                              return (
                                <p key={indexRow}>{valueRow.home_type}</p>
                              );
                            } else if (valueRow.home_type === 7) {  //Video Products
                              return (
                                <p key={indexRow}>{valueRow.home_type}</p>
                              );
                            } else if (valueRow.home_type === 8) {  //Brij Team
                              dataObj = {
                                'home_brij_team_type': valueRow.home_brij_team_type,
                                'home_brij_team_value': valueRow.home_brij_team_value,
                              };
                              return (
                                <></>
                                // <TeamList key={indexRow} dataObj={dataObj} />
                              );
                            } else if (valueRow.home_type === 9) {  //Brij Award
                              dataObj = {
                                'home_brij_award_type': valueRow.home_brij_award_type,
                                'home_brij_award_display_desc': valueRow.home_brij_award_display_desc,
                                'home_brij_award_value': valueRow.home_brij_award_value,
                              };
                              return (
                                <></>
                                // <AwardList key={indexRow} dataObj={dataObj} />
                              );
                            } else if (valueRow.home_type === 10) {  //Brij Event
                              dataObj = {
                                'home_brij_event_type': valueRow.home_brij_event_type,
                                'home_brij_event_value': valueRow.home_brij_event_value,
                              };
                              return (
                                <></>
                                // <EventList key={indexRow} dataObj={dataObj} />
                              );
                            } else if (valueRow.home_type === 11) {  //Title
                              const teamInlineStyle = convertStyleStringToObject(valueRow.home_brij_inline_style);
                              return (
                                <div className={`section-title ${valueRow.home_brij_align} ${valueRow.home_brij_extra_class}`} style={teamInlineStyle} key={indexRow}>
                                  {valueRow.home_brij_heading && React.createElement(
                                    valueRow.home_brij_heading_size || 'h1',
                                    null,
                                    valueRow.home_brij_heading
                                  )}
                                  {valueRow.home_brij_subheading && (<p>{valueRow.home_brij_subheading}</p>)}
                                </div>
                              );
                            } else if (valueRow.home_type === 12) {  //Video
                              return (
                                <div className="fillaboutsec-media withvideolink videosec" onClick={() => { handleVideoClick(indexSection) }} >
                                  <video src={valueRow.home_video} autoPlay="autoplay" loop muted playsInline style={{ width: '100%', height: '100%' }}></video>
                                </div>
                              );
                            } else if (valueRow.home_type === 15) { // Testimonials
                              return (
                                <></>
                                // <TestimonialsHomeList key={indexRow} />
                              );
                            } else if (valueRow.home_type === 16) { // Blogs
                              return (
                               
                                <HomeBlog></HomeBlog>
                                // <BlogsHomeList key={indexRow} />
                              );
                            } else if(valueRow.home_type === 17){ // Calender
                              return (
                                <></>
                            //   <Calender key={indexRow}></Calender>
                            ) 
                            }else if (valueRow.home_type === 18) { // Marquee
         
                              return (
                                <>
                                  <Marquee>
                                {valueRow.has_many_gallery.map((gallery, key)=>{
                                  return(<>
                                    <img src={mediaImageUrl+gallery.home_gallery_image_url}  />
                                    </>)
                                })}
                                 </Marquee>
                                </>
                              )
                            }else if (valueRow.home_type === 20) { 
                                                                        // Room Edit
                                 dataObj = {
                                'has_many_gallery': valueRow.has_many_gallery,
                                'home_roomedit_text': valueRow.home_roomedit_text,
                                'mediaImageUrl':mediaImageUrl
                              };
                              return (
                                <>
                                <Roomedits key={indexRow} dataObj={dataObj}></Roomedits>
                                </>
                              )
                            }
                          })}
                      </div></>
                    ))}
                </div>
              </div>
            </section>
          );
        })
      )} 
        </BrowserView>
        <MobileView>
        <HomeSlider></HomeSlider>
        <HomeBelowBanner></HomeBelowBanner>
      {mobileSectionData.length > 0 && (
        mobileSectionData.map((valueSection, indexSection) => {
          const inlineStyle = convertStyleStringToObject(valueSection.home_inline_style);
          if (valueSection.home_layout_image) {
            inlineStyle.background = `url(${valueSection.home_layout_image}) ${valueSection.home_layout_background_repeat}`;
          }
          return (
            <section className={`${valueSection.home_top_spaced} ${valueSection.home_bottom_spaced} ${valueSection.home_extra_class}`} key={valueSection.home_id} style={inlineStyle}>
              <div className={`${valueSection.home_layout} ${valueSection.home_grid === 'g-0' ? 'px-0' : ''}`}>
                <div className={`row ${valueSection.home_grid} ${valueSection.home_vertical_align} ${valueSection.home_horizontal_align}`}>
                  {valueSection.has_many_home_inner.length > 0 &&
                    valueSection.has_many_home_inner.map((valueRowInner, indexRowInner) => (
                      <><div className={"col-lg-" + valueRowInner.home_inn_structure_type} key={indexRowInner}>
                        {valueRowInner.has_many_home_details.length > 0 &&
                          valueRowInner.has_many_home_details.map((valueRow, indexRow) => {
                            let dataObj;
                            if (valueRow.home_type === 1) { //Products
                              if (valueRow.home_layout_for_type === 1) {
                                dataObj = {
                                  'id': valueRow.home_cat_id,
                                  'home_product_name': valueRow.home_product_name,
                                  'home_column': valueRow.home_column,
                                  'home_display_type': valueRow.home_display_type,
                                  'home_slider_display_title': valueRow.home_slider_display_title,
                                  'home_no_items': valueRow.home_no_items,
                                  'home_slider_display_type': valueRow.home_slider_display_type,
                                  'home_product_style': valueRow.home_product_style,
                                  'home_col_grid': 'cols-xl-',
                                };
                                return (
                                   <CategoryWiseProducts key={indexRow} dataObj={dataObj} />
                                );
                              } else {
                                
                                dataObj = {
                                  'id': valueRow.home_tag_id,
                                  'home_product_name': valueRow.home_product_name,
                                  'home_column': valueRow.home_column,
                                  'home_slider_display_title': valueRow.home_slider_display_title,
                                  'home_display_type': valueRow.home_display_type,
                                  'home_no_items': valueRow.home_no_items,
                                  'home_slider_display_type': valueRow.home_slider_display_type,
                                  'home_product_style': valueRow.home_product_style,
                                  'home_col_grid': 'cols-xl-',
                                };
                                return (
                                 <TagWiseProducts key={indexRow} dataObj={dataObj} />
                                );
                              }
                            } else if (valueRow.home_type === 2) { //Categories
                              dataObj = {
                                'home_cat_title': valueRow.home_cat_title,
                                'home_column': valueRow.home_column,
                                'home_display_type': valueRow.home_display_type,
                                'home_cat_multi_id': valueRow.home_cat_multi_id,
                                'home_cat_display_title': valueRow.home_cat_display_title,
                                'home_category_style': valueRow.home_category_style,
                              };
                              return (
                               
                                <CategoriesList
                                  key={indexRow}
                                  dataObj={dataObj}
                                />
                              );
                            } else if (valueRow.home_type === 3) { //Tags
                              return (
                                <p key={indexRow}>{valueRow.home_type}</p>
                              );
                            } else if (valueRow.home_type === 4) { //Text
                              return (
                                <div key={indexRow}>
                                  {valueRow.home_text_title && (<p>{valueRow.home_text_title}</p>)}
                                  <div dangerouslySetInnerHTML={{ __html: valueRow.home_text_description }} ></div>
                                </div>
                              );
                            } else if (valueRow.home_type === 5) { //Images
                              const imageInlineStyle = convertStyleStringToObject(valueRow.home_custom_size);
                              return (
                                <a href={valueRow.home_image_link} target={valueRow.home_target}>
                                  <img src={valueRow.home_image} key={indexRow} alt={valueRow.home_caption_text} className={`${valueRow.home_size}`} style={imageInlineStyle} />
                                </a>
                              );
                            } else if (valueRow.home_type === 6) { //Image Gallery
                              return (
                                <p key={indexRow}>{valueRow.home_type}</p>
                              );
                            } else if (valueRow.home_type === 7) {  //Video Products
                              return (
                                <p key={indexRow}>{valueRow.home_type}</p>
                              );
                            } else if (valueRow.home_type === 8) {  //Brij Team
                              dataObj = {
                                'home_brij_team_type': valueRow.home_brij_team_type,
                                'home_brij_team_value': valueRow.home_brij_team_value,
                              };
                              return (
                                <></>
                                // <TeamList key={indexRow} dataObj={dataObj} />
                              );
                            } else if (valueRow.home_type === 9) {  //Brij Award
                              dataObj = {
                                'home_brij_award_type': valueRow.home_brij_award_type,
                                'home_brij_award_display_desc': valueRow.home_brij_award_display_desc,
                                'home_brij_award_value': valueRow.home_brij_award_value,
                              };
                              return (
                                <></>
                                // <AwardList key={indexRow} dataObj={dataObj} />
                              );
                            } else if (valueRow.home_type === 10) {  //Brij Event
                              dataObj = {
                                'home_brij_event_type': valueRow.home_brij_event_type,
                                'home_brij_event_value': valueRow.home_brij_event_value,
                              };
                              return (
                                <></>
                                // <EventList key={indexRow} dataObj={dataObj} />
                              );
                            } else if (valueRow.home_type === 11) {  //Title
                              const teamInlineStyle = convertStyleStringToObject(valueRow.home_brij_inline_style);
                              return (
                                <div className={`section-title ${valueRow.home_brij_align} ${valueRow.home_brij_extra_class}`} style={teamInlineStyle} key={indexRow}>
                                  {valueRow.home_brij_heading && React.createElement(
                                    valueRow.home_brij_heading_size || 'h1',
                                    null,
                                    valueRow.home_brij_heading
                                  )}
                                  {valueRow.home_brij_subheading && (<p>{valueRow.home_brij_subheading}</p>)}
                                </div>
                              );
                            } else if (valueRow.home_type === 12) {  //Video
                              return (
                                <div className="fillaboutsec-media withvideolink videosec" onClick={() => { handleVideoClick(indexSection) }} >
                                  <video src={valueRow.home_video} autoPlay="autoplay" loop muted playsInline style={{ width: '100%', height: '100%' }}></video>
                                </div>
                              );
                            } else if (valueRow.home_type === 15) { // Testimonials
                              return (
                                <></>
                                // <TestimonialsHomeList key={indexRow} />
                              );
                            } else if (valueRow.home_type === 16) { // Blogs
                              return (
                                <HomeBlog></HomeBlog>
                                // <BlogsHomeList key={indexRow} />
                              );
                            } else if(valueRow.home_type === 17){ // Calender
                              return (
                              <></>
                            //   <Calender key={indexRow}></Calender>
                            ) 
                            }else if (valueRow.home_type === 18) { // Marquee
         
                              return (
                                <>
                                  <Marquee>
                                {valueRow.has_many_gallery.map((gallery, key)=>{
                                  return(<>
                                    <img src={mediaImageUrl+gallery.home_gallery_image_url}  />
                                    </>)
                                })}
                                 </Marquee>
                                </>
                              )
                            }else if (valueRow.home_type === 20) { // Room Edit
                                dataObj = {
                                    'has_many_gallery': valueRow.has_many_gallery,
                                    'home_roomedit_text': valueRow.home_roomedit_text,
                                    'mediaImageUrl':mediaImageUrl
                                  };
                                  return (
                                    <>
                                    <Roomedits key={indexRow} dataObj={dataObj}></Roomedits>
                                    </>
                                  )
                            }
                          })}
                      </div></>
                    ))}
                </div>
              </div>
            </section>
          );
        })
      )} 
    </MobileView>
       
        {/* <HomeBelowBanner></HomeBelowBanner> */}
        {/* <HomeCollections></HomeCollections>
        <HomeWhatsNew></HomeWhatsNew>
        <HomeNewIn></HomeNewIn>
        <HomeShopVideo></HomeShopVideo>
        <HomeBuyBulk></HomeBuyBulk>
        <HomeAppointment></HomeAppointment>
        <Roomedits></Roomedits>
        <HomeBlog></HomeBlog>
        <HomeTopFooter></HomeTopFooter> */}
       
        <Footer></Footer>


    </>)
}

export default Home