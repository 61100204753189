import Footer from "../../Components/Footer"
import Header from "../../Components/Header"
import ProductSlider from "./Elements/product_slider"
import ProductRight from "./Elements/product_right"
import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ApiService } from "../../Components/Services/apiservices";
import constant from "../../Components/Services/constant";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from "swiper/modules";
import { Navigation, Pagination, A11y } from 'swiper/modules';
import ProductBox from "../../Components/Elements/Product_box";
import ShippingTabs from "../../Components/FooterTabs";


const ProductDetail = () => {
    const { slug } = useParams()
    const [spinnerLoading, setSpinnerLoading] = useState(true);
    const [galleryItems, setGalleryItems] = useState([]);
    const [rowProductsData, setProductsData] = useState([]);
    const [producttabs, setproducttabs] = useState([])
    const [variationDataa, setVariationData] = useState([]);
    const [arySelectedData, setArySelectedData] = useState([]);
    const [relatedProducts, setrelatedProducts] = useState([]);
    const [selvararray, setSelvararray] = useState([]);
    const [resCompleteTheLooksData, setResCompleteTheLooksData] = useState([]);
    const [termImageBaseUrl, setTermImageBaseUrl] = useState('');

    const didMountRef = useRef(true);

    useEffect(() => {
        if (didMountRef.current) {
            const dataString = {
                slug: slug,
            };
            ApiService.postData("product-details", dataString).then((res) => {
                if (res.status == "success") {

                    setProductsData(res.rowProductData);
                    if (res.rowProductData.product_description !== null) {
                        const producttabs = [
                            {
                                tab_name: 'Description',
                                tab_description: res.rowProductData.product_description
                            },
                        ];
    
                        res.rowProductData.product_tabs && res.rowProductData.product_tabs?.map((value) => {
                            producttabs.push(value);
                        });
                          
                   
                      
                        setproducttabs(producttabs)
                    } else {
                        const producttabs =
                            res.rowProductData.product_tabs?.map((value) => {
                                producttabs.push(value);
                            });
                          
                       
                       
                        setproducttabs(producttabs)
                    }
                 
                    setTermImageBaseUrl(res.termImageBaseUrl)
                    // setadminData(res.admin_data)
                    setrelatedProducts(res.relatedProducts)
                    setVariationData(res.variationData);
                    setSelvararray(res.selvararray);
                    res.variationData.map((parent) => {
                        if (parent.attributes && Number(parent.attributes.attribute_type) === 3) { //Text
                            parent.attr_terms.map((child) => {
                                if (res.selvararray.includes(child.terms_name)) {
                                    arySelectedData.push({ attr: parent.attributes.attribute_name, terms: child.terms_name, terms_value: child.terms_name, terms_type: 3 });
                                }
                            });
                        } else if (parent.attributes && Number(parent.attributes.attribute_type) === 2) { //Image
                            parent.attr_terms.map((child) => {
                                if (res.selvararray.includes(child.terms_name)) {
                                    arySelectedData.push({ attr: parent.attributes.attribute_name, terms: child.terms_name, terms_value: child.terms_image, terms_type: 2 });
                                }
                            });
                        } else if (parent.attributes && Number(parent.attributes.attribute_type) === 1) { //Color
                            parent.attr_terms.map((child) => {
                                if (res.selvararray.includes(child.terms_name)) {
                                    arySelectedData.push({ attr: parent.attributes.attribute_name, terms: child.terms_name, terms_value: child.terms_value, terms_type: 1 });
                                }
                            });
                        }
                    });
                    saveToRecentlyViewed(res.rowProductData, res.arrayImages)
                    const galleryItems = [
                        {
                            original:
                                res.rowProductData.product_image != null
                                    ? res.rowProductData.product_image
                                    : constant.DEFAULT_IMAGE,
                            thumbnail:
                                res.rowProductData.product_image != null
                                    ? res.rowProductData.product_image
                                    : constant.DEFAULT_IMAGE,
                        },
                    ];



                    res.arrayImages.map((value) => {
                        galleryItems.push({
                            original:
                                value != null
                                    ? value
                                    : constant.DEFAULT_IMAGE,
                            thumbnail:
                                value != null
                                    ? value
                                    : constant.DEFAULT_IMAGE,
                        });
                    });

                    setGalleryItems(galleryItems);
                    setSpinnerLoading(false);
                } else {
                    setSpinnerLoading(false);
                }
            }).catch((e) => {
                console.log(e)
                setSpinnerLoading(false)
            });
        }
        didMountRef.current = false;
    }, []);
    const handleDataFromChild = (updatedData, arrayImages, rowProductsData) => {
        setArySelectedData(updatedData);
        const galleryItems = [
            {
                original:
                    rowProductsData.product_image != null
                        ? rowProductsData.product_image
                        : constant.DEFAULT_IMAGE,
                thumbnail:
                    rowProductsData.product_image != null
                        ? rowProductsData.product_image
                        : constant.DEFAULT_IMAGE,
            },
        ];
        arrayImages.map((value) => {
            galleryItems.push({
                original:
                    value != null
                        ? value
                        : constant.DEFAULT_IMAGE,
                thumbnail:
                    value != null
                        ? value
                        : constant.DEFAULT_IMAGE,
            });
        });

        setGalleryItems(galleryItems);
        setProductsData(rowProductsData);
    };
    const saveToRecentlyViewed = (product, arrayImages) => {

        let recentlyViewed = JSON.parse(localStorage.getItem('RECENTLY_VIEWED')) || [];
        const existingProduct = recentlyViewed.find(item => item.product_slug === product.product_slug);
        if (!existingProduct) {
            const data = {
                arrayImages: arrayImages,
                product_price: product.product_price,
                product_selling_price: product.product_selling_price,
                product_name: product.product_name,
                product_slug: product.product_slug,

            }
            recentlyViewed.unshift(data);
            recentlyViewed = recentlyViewed.slice(0, 25);
            localStorage.setItem('RECENTLY_VIEWED', JSON.stringify(recentlyViewed));
        }
    };
    let recentlyViewed = JSON.parse(localStorage.getItem('RECENTLY_VIEWED'))?.reverse() || [];
    return (<>
        <Header></Header>
        <section className="section-gap-medium single-product">
            <div className="container">
                <div className="row">
                    <div className="col-lg-7">
                        <ProductSlider galleryItems={galleryItems} />
                    </div>
                    <div className="col-lg-5">
                        <ProductRight rowProductsData={rowProductsData}
                            arySelectedData={arySelectedData}
                            variationDataa={variationDataa}
                            termImageBaseUrl={termImageBaseUrl}
                            producttabs={producttabs}
                            sendDataToParent={handleDataFromChild}
                            relatedProducts={relatedProducts}
                            galleryItems={galleryItems}
                            selvararray={selvararray} />
                    </div>
                </div>
            </div>
        </section>
        {/* {producttabs && producttabs.length > 0 && (
            <section className="section-gap-medium ">
                <div className="container-fluid">
                    {producttabs.map((item , index)=>{
                        return(<>
                         <div className="section-title text-center mb-40">
                        <h2>{item.tab_name}</h2>
                    </div>
                    <div className="row" key={index}>
                        <div className="col-lg-12">
                            <div dangerouslySetInnerHTML={{__html:item.tab_description}}>
                            </div>

                        </div>
                    </div>
                        
                        </>)
                    })}
                   
                </div>
            </section>
        )} */}
        {recentlyViewed && recentlyViewed.length > 0 && (
            <section className="section-gap-medium ">
                <div className="container">
                    <div className="section-title text-center mb-40">
                        <h2>Recently Viewed</h2>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div>
                                <Swiper
                                    pagination={{ clickable: true }}
                                    // autoplay={{
                                    //     delay: 2500,
                                    //     disableOnInteraction: false,
                                    // }}
                                    modules={[Navigation, Pagination, A11y, Autoplay]}
                                    loop={true}

                                    breakpoints={{
                                        360: { // For screen width 360px
                                            slidesPerView: 2,
                                            spaceBetween: 10,
                                        },
                                        640: {
                                            slidesPerView: 2,
                                            spaceBetween: 20,
                                        },
                                        768: {
                                            slidesPerView: 2,
                                            spaceBetween: 20,
                                        },
                                        1024: {
                                            slidesPerView: 5,
                                            spaceBetween: 20,
                                        },
                                    }}

                                >
                                    {recentlyViewed.map((item, index) => {
                                        return (<>
                                            <SwiperSlide >
                                                <ProductBox productValue={item} classType="product tx-center" key={index} home_product_style={2}></ProductBox>
                                            </SwiperSlide>
                                        </>)
                                    })}
                                </Swiper>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )}
        {relatedProducts && relatedProducts.length > 0 && (
            <section className="section-gap-medium ">
                <div className="container">
                    <div className="section-title text-center mb-40">
                        <h2>This Might Interest You</h2>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div>
                                <Swiper
                                    pagination={{ clickable: true }}
                                    // autoplay={{
                                    //     delay: 2500,
                                    //     disableOnInteraction: false,
                                    // }}
                                    modules={[Navigation, Pagination, A11y, Autoplay]}
                                    loop={true}

                                    breakpoints={{
                                        360: { // For screen width 360px
                                            slidesPerView: 2,
                                            spaceBetween: 10,
                                        },
                                        640: {
                                            slidesPerView: 2,
                                            spaceBetween: 20,
                                        },
                                        768: {
                                            slidesPerView: 2,
                                            spaceBetween: 20,
                                        },
                                        1024: {
                                            slidesPerView: 5,
                                            spaceBetween: 20,
                                        },
                                    }}

                                >
                                    {relatedProducts.map((item, index) => {
                                        return (<>
                                            <SwiperSlide >
                                                <ProductBox productValue={item} classType="product tx-center" key={index} home_product_style={2}></ProductBox>
                                            </SwiperSlide>

                                        </>)
                                    })}


                                </Swiper>


                            </div>

                        </div>
                    </div>
                </div>
            </section>
        )}
        
        <ShippingTabs></ShippingTabs>

       

        <Footer></Footer>

    </>)
    
}

export default ProductDetail