import React, { useEffect, useRef, useState, useCallback } from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from "swiper/modules";
import { BrowserView, MobileView } from "react-device-detect";
import { Navigation, Pagination, Scrollbar, A11y, EffectFade } from 'swiper/modules';



const Roomedits = ({ dataObj }) => {
    const sliderCatRef = useRef(null);
    const handlePrev = useCallback(() => {
        if (!sliderCatRef.current) return;
        sliderCatRef.current.swiper.slidePrev();
    }, []);

    const handleNext = useCallback(() => {
        if (!sliderCatRef.current) return;
        sliderCatRef.current.swiper.slideNext();
    }, []);
    return (<>

        <section className="section-gap-md">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-4">
                        <div className="roomeditcolm">
                            <div className="roomeditcolmtoph">
                                <h2>Edits</h2>
                                <div className="roomshareicon">
                                    <i class="ri-instagram-line"></i> Rukman Creations
                                </div>
                            </div>
                            <h2>Room</h2>
                         {dataObj?.home_roomedit_text ?<p>{dataObj?.home_roomedit_text}</p>:''}   
                        </div>
                    </div>
                    {dataObj.has_many_gallery && dataObj.has_many_gallery?.length > 0 ? <>
                        <div className="col-lg-8">
                            <div className="roomgallery">
                                <Swiper
                                    // ref={sliderCatRef}
                                    spaceBetween={20}
                                    modules={[Navigation, Scrollbar,]}
                                    slidesPerView={2.5}

                                >
                                    {dataObj.has_many_gallery?.map((item, index) => {
                                        return (<>
                                            <SwiperSlide key={index}>
                                                <img src={dataObj.mediaImageUrl + item.home_gallery_image_url} alt="room_edit"></img>
                                            </SwiperSlide>
                                        </>)
                                    })}
                                </Swiper>
                            </div>
                        </div>
                    </> : ''}

                </div>
            </div>
        </section>

    </>)
}

export default Roomedits